import { dateFormat, enFormatDate } from "../actions/dateFormated";

const inputGlobal = (data, input) => {
  const search = input.toLocaleLowerCase();
  return data.filter((object) => {
    const badge = object?.numvisiteur?.toLocaleLowerCase() || "";
    const nom = object?.visiteur?.nom?.toLocaleLowerCase() || "";
    const prenom = object?.visiteur?.prenom.toLocaleLowerCase() || "";
    const denomination =
      object?.visiteur?.societe?.denomination?.toLocaleLowerCase() || "";
    const libelle =
      object?.visiteur?.categorieVisiteur?.libelle?.toLocaleLowerCase() || "";
    const personne = object?.personnelVisite?.nom?.toLocaleLowerCase() || "";
    const cin = object?.visiteur?.cin?.toLocaleLowerCase() || "";
    return (
      badge.indexOf(search) > -1 ||
      nom.indexOf(search) > -1 ||
      prenom.indexOf(search) > -1 ||
      denomination.indexOf(search) > -1 ||
      libelle.indexOf(search) > -1 ||
      personne.indexOf(search) > -1 ||
      cin.indexOf(search) > -1
    );
  });
};

const inputDate = (data, date) => {
  return data.filter((object) => {
    const createdAt = object?.dateEntre
      ? new Date(enFormatDate(dateFormat(object.dateEntre))).getTime()
      : null;
    return enFormatDate(date).getTime() === createdAt;
  });
};

const callCreatedAt = (data, date) => {
  return data.filter((object) => {
    const createdAt = object?.dateEntre
      ? new Date(enFormatDate(dateFormat(object.dateEntre))).getTime()
      : null;
    return enFormatDate(date).getTime() === createdAt;
  });
};

const callBackedAt = (data, date) => {
  return data.filter((object) => {
    const updateAt = object?.dateSortie
      ? new Date(enFormatDate(dateFormat(object?.dateSortie))).getTime()
      : null;
    return enFormatDate(date).getTime() === updateAt && updateAt;
  });
};

const filterByDateRange = (data, startDate, endDate) => {
  return data.filter((object) => {
    const dateEntre = object?.dateEntre
      ? new Date(enFormatDate(dateFormat(object.dateEntre))).getTime()
      : null;
    const dateSortie = object?.dateSortie
      ? new Date(enFormatDate(dateFormat(object.dateSortie))).getTime()
      : null;

    if (startDate && endDate) {
      // Filtrer entre deux dates
      const start = enFormatDate(startDate).getTime();
      const end = enFormatDate(endDate).getTime();
      return dateEntre >= start && dateEntre <= end;
    } else if (startDate) {
      // Filtrer par date antrée
      return enFormatDate(startDate).getTime() === dateEntre;
    } else if (endDate) {
      // Filtrer par date sortie
      return enFormatDate(endDate).getTime() === dateSortie;
    }

    return true;
  });
};

const callVisitorItems = (data, visitorItems) => {
  return data.filter((object) => {
    const name =
      object?.visiteur?.nom?.toLocaleLowerCase() +
      " " +
      object?.visiteur?.prenom?.toLocaleLowerCase();
    return name === visitorItems.toLocaleLowerCase();
  });
};

const callEnterpriseItems = (data, enterpriseItems) => {
  return data.filter((object) => {
    const denomination =
      object?.visiteur?.societe?.denomination?.toLocaleLowerCase();
    return denomination === enterpriseItems.toLocaleLowerCase();
  });
};

const callCategoryItems = (data, enterpriseItems) => {
  return data.filter((object) => {
    const libelle =
      object?.visiteur?.categorieVisiteur?.libelle?.toLocaleLowerCase();
    return libelle === enterpriseItems.toLocaleLowerCase();
  });
};

const callVisitedItems = (data, visitedItems) => {
  return data.filter((object) => {
    const personne =
      object?.personnelVisite?.nom?.toLocaleLowerCase() +
      " " +
      object?.personnelVisite?.prenom?.toLocaleLowerCase();
    return personne === visitedItems.toLocaleLowerCase();
  });
};

export const visitorSearch = (
  data,
  input = "",
  date = null,
  createdAt = null,
  backedAt = null,
  visitorItems = "",
  enterpriseItems = "",
  categoryItems = "",
  visitedItems = ""
) => {
  let results = inputGlobal(data, input);

  if (date) results = inputDate(results, date);
  if (createdAt) results = callCreatedAt(results, createdAt);
  if (backedAt) results = callBackedAt(results, backedAt);

  if (visitorItems !== "" && visitorItems !== "Tous")
    results = callVisitorItems(results, visitorItems);

  if (enterpriseItems !== "" && enterpriseItems !== "Tous")
    results = callEnterpriseItems(results, enterpriseItems);

  if (categoryItems !== "" && categoryItems !== "Tous")
    results = callCategoryItems(results, categoryItems);

  if (visitedItems !== "" && visitedItems !== "Tous")
    results = callVisitedItems(results, visitedItems);

  return results;
};

export const rapportSearch = (
  data,
  input = "",
  date = null,
  createdAt = null,
  backedAt = null,
  visitorItems = "",
  enterpriseItems = "",
  categoryItems = "",
  visitedItems = ""
) => {
  let results = inputGlobal(data, input);

  // Appliquer le filtre de date
  results = filterByDateRange(results, createdAt, backedAt);

  if (visitorItems !== "" && visitorItems !== "Tous")
    results = callVisitorItems(results, visitorItems);

  if (enterpriseItems !== "" && enterpriseItems !== "Tous")
    results = callEnterpriseItems(results, enterpriseItems);

  if (categoryItems !== "" && categoryItems !== "Tous")
    results = callCategoryItems(results, categoryItems);

  if (visitedItems !== "" && visitedItems !== "Tous")
    results = callVisitedItems(results, visitedItems);

  return results;
};
